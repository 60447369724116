<script>
    import {__, data$, getCookieValue, setLanguage, user$} from "../js/lib.js";
    import A from "../lib/Router/A.svelte";

    const lang = getCookieValue('dd_eshop_lang');
</script>

<div class="row">
    <div class="small-6 columns">
        <div style="margin:10px 0;">
            {$user$.fullname} (<a href="/logout.php">Logout</a>)
        </div>
        
    </div>
    <div class="small-6 columns text-right">
        <div style="margin:10px 0;">
            {#if $user$.is_admin}
                <A href="/translations" class="tag ">{__('Μεταφράσεις')}: {$data$.translations.length}</A>
                <A href="/new" class="tag new">{__('Νέες')}: {$data$.newPhotos}</A>
            {/if}
            
            <A href="/talk" class="tag talk">{__('Συζήτηση')}: {$data$.talkedPhotos}</A>
        </div>
    </div>

    <div class="small-12 columns">
        <p>&nbsp;</p>
        <img src="/img/header_login.png">
        <p>&nbsp;</p>
    </div>
</div>

<style>
    .btn {
        display: inline-block;
        padding: 2px 10px;
        background: #fff;
        border:1px solid #ddd;
        border-radius: 4px;
        color:#ccc;
    }

    .btn.selected {
        background: #fe871f;
        color:#fff;
    }
</style>