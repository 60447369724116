<script>
    export let title;
</script>

<div class="popup">
    <div class="window">
        <h2>{title}</h2>
        <div>
            <slot/>
        </div>
    </div>
</div>

<style>
    .popup {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        background-color: #111d;
    }

    .window {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: #fff;
        border: 1px solid #000;
        transform: translate(-50%, -50%);
    }

    .window h2 {
        padding: 10px;
        background-color: #000;
        color: #fff;
    }

    .window > div {
        padding: 10px;
    }

    .window button[disabled] {
        background-color: #ddd;
    }

    .window button[disabled]:hover {
        background-color: #ddd;
    }
    </style>