<script>
    import { router } from "./Router.svelte";
    import { is_function } from "svelte/internal";

    let href = "#";
    let natural = false;
    let useActive = null;
    let useExactActive = null;
    let classNames = "";
    let isAllowed = true;
    let visible = () => true;

    export {
        visible,
        href,
        natural,
        useActive,
        useExactActive,
        classNames as class,
    };

    const visibilityCheck = is_function(visible) ? visible : () => visible;



    let activeClass = "";

    const check = (store, callback) => {
        isAllowed = true;
        if (callback) {
            isAllowed = callback();
            if (!isAllowed) {
                return;
            }
        }

        activeClass = "";

        if (useExactActive !== null && store.url == href) {
            activeClass = useExactActive === true ? "link-active" : useActive;
            return;
        }

        if (
            useActive !== null &&
            (store.url == href || store.url.startsWith(`${href}/`))
        ) {
            activeClass = useActive === true ? "link-active" : useActive;
        }
    };

    const clickHandler = (href) => (e) => {
        if (href == "" || href == "#") {
            return;
        }

        if (e.metaKey || e.ctrlKey) {
            window.open(href);
        }

        router.go(href, { preventDefault: true })();
    };

    $: check($router, visibilityCheck);
</script>

{#if isAllowed}
    {#if natural}
        <a {href} class="{activeClass} {classNames}" on:click>
            <slot />
        </a>
    {:else}
        <a
            {href}
            class="{activeClass} {classNames}"
            on:click|preventDefault={clickHandler(href)}
            on:click
        >
            <slot />
        </a>
    {/if}
{/if}
