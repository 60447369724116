<script>
    import {__, API, cats$, getLang, user$} from "../js/lib.js";
    import SinglePhoto from "./SinglePhoto.svelte";
    import PhotoPopup from "./PhotoPopup.svelte";

    export let fromTool='';

    let loaded = false;
    let photos = [];
    let page = 1;
    let totalPages = 1;
    let catId = 0;

    let selectedPhoto = null;

    let filtered = [];

    const lang = getLang();
    
    const init = async () => {
        loaded = false;
        await loadPage(page, catId);
        loaded = true;
    };

    const loadPage = async (page, catId = 0) => {
        const res = await API.get(fromTool, {
            page,
            catId
        });
        ({photos, page, totalPages} = res.data);
        photos.map(photo => {
            photo.canView = $user$.is_super_admin || photo.comments?.lang == lang;
            photo.needsTranslation = $user$.is_admin && photo.comments?.lang == null;
        });
    };

    const setCat = id => {
        page = 1;
        totalPages = 1;
        catId = id;
        loadPage(page, catId)
    };

    const removeMe = ({detail: photo}) => {
        photos = photos.filter(p => p != photo);
    };

    const filterPhotos = (p, c) => {
        if (c == 0) {
            return p;
        }

        return p.filter(photo => photo.cat_id == c);
    };

    const displayLightbox = ({detail:photo}) => selectedPhoto = photo;

    init();

    $: filtered = filterPhotos(photos, catId);
</script>


<div class="{fromTool}-panel row">
    {#if loaded}
        <div class="row">
            <div class="column small-12 medium-3 side-menu">
                <h3 class={fromTool}>{__('Κατηγορίες')}</h3>
                {#each $cats$ as cat}
                    <div class="text-link a-cat" class:selected={catId == cat.id}
                         on:click={setCat(cat.id)}>{cat.name[lang]}</div>
                {/each}
            </div>

            <div class="column small-12 medium-9">
                <div class="flex2">
                    {#if (filtered.length)}
                        {#each filtered as photo (photo.id)}
                            <SinglePhoto
                                    {fromTool}
                                    {photo}
                                    on:remove={removeMe}
                                    on:lightbox={displayLightbox}
                            />
                        {/each}
                    {:else}
                        <div style="background-color: #fff; padding:20px; text-align: center">
                            {__('Δεν υπάρχει καμία φωτογραφία')}
                        </div>
                    {/if}
                </div>
            </div>
        </div>
    {:else}
        please wait...
    {/if}
</div>

{#if selectedPhoto}
    <PhotoPopup
            photo={selectedPhoto}
            title="Photo"
            on:close={() => selectedPhoto = null}
            {photos}
    />
{/if}

<style>
    .side-menu {
        background: #fff;
        position: relative;
        padding: 16px 10px;
        border: 1px solid #aaa;
        box-shadow: 3px 3px 8px 0 #0003;
    }

    .side-menu h3 {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #aaa;
    }

    .side-menu .a-cat {
        padding: 5px 10px;
    }

    .side-menu .a-cat:hover {
        background-color: cornsilk;
    }

    .side-menu .a-cat.selected {
        background-color: chocolate;
        color: #fff;
    }

    .dos-panel {
        padding: 30px;
        background-color: #f3f9eb;
    }
    .donts-panel {
        padding: 30px;
        background-color: #f9e8f2;
    }

    h3.dos {
        color: #8bc03e;;
    }
    h3.donts {
        color: #a30a5e;
    }

</style>