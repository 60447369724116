<script>
    import {__, API, cats$, data$, DMYHI, getLang, showMessage, user$} from "../js/lib.js";
    import CatChooser from "./CatChooser.svelte";
    import CommentsPopup from "./CommentsPopup.svelte";
    import {createEventDispatcher, tick} from "svelte/internal";
    import AllChats from "./AllChats.svelte";
    import MessageBox from "./MessageBox.svelte";


    export let photo;

    const dispatch = createEventDispatcher();

    let showCatChooser = false;
    let showDoForm = false;
    let showDontForm = false;
    let showEditDescription = false;

    const lang = getLang();
    const username = $data$.users.find(u => u.id == photo.user_id).fullname;
    let category = $cats$.find(c => c.id == photo.cat_id).name;
    const date = DMYHI(photo.created_at)

    const changeCategory = async ({detail: cat_id}) => {
        if (cat_id === null) {
            showCatChooser = false;
            return;
        }
        const res = await API.post('changeCategory', {
            id: photo.id,
            cat_id
        });

        if (res.data == true) {
            showCatChooser = false;
            category = $cats$.find(c => c.id == cat_id).name;
            photo.cat_id = cat_id;
            showMessage(__('Η κατηγορία άλλαξε'));
        } else {
            alert(__('Κάποιο λάθος συνέβη'));
        }
    };

    const saveDo = async ({detail: comments}) => {
        const res = await API.post('addDo', {
            id: photo.id,
            comments: comments[lang]
        });

        if (res.data == true) {
            showDoForm = false;
            $data$.talkedPhotos = $data$.talkedPhotos - 1;
            showMessage(__('Η αποθήκευση έγινε'));
            await tick();
            dispatch('remove');
        } else {
            alert(__('Κάποιο λάθος συνέβη'));
        }
    };

    const saveDont = async ({detail: comments}) => {
        const res = await API.post('addDont', {
            id: photo.id,
            comments: comments[lang]
        });

        if (res.data == true) {
            showDontForm = false;
            showMessage(__('Η αποθήκευση έγινε'));
            await tick();
            dispatch('remove');
        } else {
            alert(__('Κάποιο λάθος συνέβη'));
        }
    };

    const deletePhoto = async () => {
        if (!confirm('Are you sure?')) {
            return;
        }

        const res = await API.post('deletePhoto', {
            id: photo.id,
        });

        if (res.data == true) {
            showMessage(__('Η διαγραφη έγινε'));
            await tick();
            dispatch('remove');

        } else {
            alert(__('Κάποιο λάθος συνέβη'));
        }
    };

    const saveDescription = async ({detail: comments}) => {
        const res = await API.post('editPhotoDescription', {
            id: photo.id,
            comments: comments[lang]
        });

        if (res.data == true) {
            photo.comments = comments;
            showMessage(__('Η επεξεργασία έγινε'));
            showEditDescription = false;
        } else {
            alert(__('Κάποιο λάθος συνέβη'));
        }
    };

    const addChatToPhoto = ({detail: item}) => {
        photo.chats.push(item);
        photo = photo;
    };
</script>


<div class="column small-12">
    <div class="talk-photo">
        <div class="row">
            <div class="column small-12 ribbon">
                <span class="pull-right">{username}, {date}</span>
                <span class="tag link" on:click={() => showCatChooser = true}>
                    {category[lang]}
                </span>
            </div>

            <div class="column small-3">
                <img src="/{photo.filename}" alt="">
                {#if $user$.is_admin}
                    <div style="padding:20px 0 0 20px;">
                        <button class="dos" on:click={() => showDoForm = true}>
                            {__('Στα DOs')}
                        </button>

                        <button class="donts" on:click={() => showDontForm = true}>
                            {__('Στα DONTs')}
                        </button>

                        <button class="delete" on:click={deletePhoto}>{__('Διαγραφή')}</button>
                    </div>
                {/if}
            </div>

            <div class="column small-9">
                <div class="bold pad-v">
                    {#if photo.comments[lang].length > 1}
                        {photo.comments[lang]}
                    {:else}
                        ({__('χωρίς περιγραφή')})
                    {/if}
                    {#if $user$.is_admin}
                        <img class="pull-right link" src="/img/edit.png" alt="edit"
                             on:click={() => showEditDescription = true}>
                    {/if}
                </div>
                {#key photo}
                    <AllChats chats={photo.chats}/>
                {/key}
                <MessageBox {photo} on:save={addChatToPhoto}/>
            </div>
        </div>
    </div>
</div>

{#if $user$.is_admin}
    {#if showCatChooser}
        <CatChooser on:select={changeCategory} selected={photo.cat_id}/>
    {/if}

    {#if showDoForm}
        <CommentsPopup title="Προσθήκη στα DOs" on:save={saveDo} on:close={() => showDoForm = false}>
            <span class="tag do-colors">{__('Θα προσθέσετε αυτή τη φωτογραφία στα')} DOs</span><br>
            {__('Παρακαλώ δώστε τα σχόλιά σας για αυτή τη φωτογραφία')} ({__('προαιρετικό')}).
        </CommentsPopup>
    {/if}
    {#if showDontForm}
        <CommentsPopup title="Προσθήκη στα DONTs" on:save={saveDont} on:close={() => showDontForm = false}>
            <span class="tag dont-colors">{__('Θα προσθέσετε αυτή τη φωτογραφία στα')} DONTs</span><br>
            {__('Παρακαλώ δώστε τα σχόλιά σας για αυτή τη φωτογραφία')} ({__('προαιρετικό')}).
        </CommentsPopup>
    {/if}

    {#if showEditDescription}
        <CommentsPopup on:save={saveDescription} comments={photo.comments} on:close={() => showEditDescription = false}>
            {__('Δώστε περιγραφή για την φωτογραφία')}
        </CommentsPopup>
    {/if}
{/if}

<style>
    .talk-photo {
        background: #fff;
        position: relative;
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #aaa;
        box-shadow: 3px 3px 8px 0 #0006;
    }

    .talk-photo img {
        object-fit: contain;

    }

    .talk-photo button {
        display: block;
        width: 100%;
        float: right;
        margin-top: 5px;
        margin-left: 15px;
    }
</style>