<script>
    import {createEventDispatcher} from "svelte/internal";
    import Popup from "./Popup.svelte";
    import {__, getLang} from "../js/lib.js";

    export let title = 'Σχόλια';
    export let comments = {};

    const lang = getLang();

    const dispatch = createEventDispatcher();

    const save = () => {
        dispatch('save', comments);
    };

    const cancel = () => {
        dispatch('close', null);
    };
</script>

<Popup title="{ title }">
    <slot/>
    <div style="padding:10px;">
        <textarea bind:value={comments[lang]}></textarea>
    </div>
    <hr>
    <button class="do pull-right" on:click={save}>{__('Αποθήκευση')}</button>
    <button class="dont pull-right" on:click={cancel}>{__('Ακυρο')}</button>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
</Popup>


<style>
    textarea {
        display: block;
        min-height: 240px;
    }
</style>