<script>
    import {__, cats$, getLang} from "../js/lib.js";
    import {createEventDispatcher} from "svelte/internal";
    import Popup from "./Popup.svelte";

    export let selected = 0;

    const lang = getLang();

    const dispatch = createEventDispatcher();

    const click = id => () => {
        dispatch('select', id)
    };

    const cancel = () => {
        dispatch('select', null)
    };
</script>

<Popup title="Επιλέξτε κατηγορία">
    <p>{ __('Κάντε click στην κατηγορία που θέλετε να μεταφέρετε την φωτογραφία')}</p>
    <div style="padding:10px;">
        {#each $cats$ as cat}

            <div class="block text-link" on:click={click(cat.id)}>
                {#if selected == cat.id}
                    <b>&bull;</b>
                {/if}
                {cat.name[lang]}
            </div>
        {/each}
    </div>
    <hr>
    <button class="dont pull-right" on:click={cancel}>{__('Ακυρο')}</button>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
</Popup>


<style>
    .block.text-link {
        padding: 10px;
        background-color: #eee;
        margin-bottom: 2px;
        text-decoration: none;
    }

    .block.text-link:hover {
        background-color: #ddd;
    }


</style>