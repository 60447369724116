<script>
    import {API} from "../js/lib.js";
    import TalkedPhoto from "./TalkedPhoto.svelte";

    let loaded = false;
    let photos = [];
    let page = 1;
    let totalPages = 1;
    let catId = 0;

    const init = async () => {
        await loadPage(page, catId);
        loaded = true;
    };

    const loadPage = async (page, catId = 0) => {
        const res = await API.get('talkedPhotos', {
            page,
            catId
        });
        ({photos, page, totalPages} = res.data);
        loaded = true;
    };

    const removeMe = photo => () => {
        photos = photos.filter(p => p != photo);
    };

    init();
</script>

{#if loaded}
    <div class="row">
        <div class="column small-12">
            {#each photos as photo}
                <TalkedPhoto
                        {photo}
                        on:remove={removeMe(photo)}
                />
            {/each}
        </div>
    </div>
{:else}
    please wait...
{/if}









