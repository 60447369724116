<script>
    import FileUploader from "./FileUploader.svelte";
    import {user$} from "../js/lib.js";

    let fileCount = [true];

    const checkForm = event => {

        const col = event.target.querySelectorAll('input[type="hidden"]');

        let hasError = false;

        col.forEach(cat => {
            dd(cat);
            if (cat.value == 0) {
                hasError = true;
            }
        });

        if (hasError) {
            alert('Παρακαλώ επιλέξτε κατηγορία');
            event.preventDefault();
        }
    }

</script>


<div class="row bg-somon">
    <form method="post" action="upload.php?uid={$user$.id}" enctype="multipart/form-data" on:submit={checkForm}>
        <div class="column small-12">
            <p>&nbsp;</p>
            {#each fileCount as isActive,idx}
                {#if (isActive)}
                    <div class="upl-container">
                        <FileUploader
                                id={idx}
                                on:filled={()=>fileCount = [...fileCount, true]}
                                on:delete={() => fileCount[idx] = false}
                        />
                    </div>
                {/if}
            {/each}
            <p>&nbsp;</p>
        </div>
        {#if fileCount.length > 1}
            <div class="column small-12 text-center">
                <div class="upl-container">
                    <input type="image" src="/img/upload_button.png"/>
                </div>
            </div>
        {/if}
    </form>
</div>


<style>
    .upl-container {
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        margin-bottom: 20px;
        background-color: #fff;
    }
</style>