<script>
    import {__} from "../js/lib.js";
</script>

<div class="row">
    <div class="small-12 columns" style="margin-top:20px;">
        <img src="/img/header_nologin.png">
        <p>&nbsp;</p>
    </div>
</div>

<form method="post" action="/login.php" onsubmit="check_form()" class="frm-login">
    <div class="row">
        <p>&nbsp;</p>
        <div class="small-6 columns text-right"><label>User</label></div>
        <div class="small-6 columns"><input type="text" name="username"></div>
    </div>

    <div class="row">
        <div class="small-6 columns text-right"><label>Password</label></div>
        <div class="small-6 columns"><input type="password" name="password"></div>
    </div>

    <div class="row">
        <div class="small-6 columns">&nbsp;</div>
        <div class="small-6 columns">
            <input type="submit" value="OK">
        </div>
    </div>
</form>