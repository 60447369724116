<script>
    import A from "../lib/Router/A.svelte";

    export let selected = 'upload';
    const myTab = {
        upload: '1',
        dos: '2',
        donts: '3',
    }[selected] || '1';
</script>

<div class="row">
    <div class="small-12 columns tab-{myTab}" id="top-tabs" style="background-image: url('/img/labels.png'); ">
        <A href="/upload" class="tab-1"></A>
        <A href="/dos" class="tab-2"></A>
        <A href="/donts" class="tab-3"></A>
    </div>
</div>