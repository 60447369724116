<script>
    import {createEventDispatcher, tick} from 'svelte/internal'
    import {__, cats$, getLang} from "../js/lib.js";

    export let id;

    let loaded = false;
    let uploadControl;
    let image;
    let catId = 0;
    
    const lang = getLang();
    dd(lang, '-----------')
    dd($cats$)
    
    const dispatch = createEventDispatcher();

    const enable = async () => {
        loaded = true;
        await tick();
        uploadControl.click();
        uploadControl.addEventListener('change', changeHandler)
    };

    const deleteMe = () => {
        uploadControl.removeEventListener('change', changeHandler);
        loaded = false;
        dispatch('delete');
    };

    const changeHandler = async evt => {
        const [file] = uploadControl.files;
        if (file) {
            loaded = true;
            await tick();
            image.src = URL.createObjectURL(file);
            dispatch('filled');
            uploadControl.removeEventListener('change', changeHandler);
        }
    }
</script>


{#if loaded}
    <input type="file" name="file[{id}]" bind:this={uploadControl} style="display: none"/>
    <input type="hidden" name="cat[{id}]" bind:value={catId}>
    <div class="row">
        <div class="small-6 columns">
            <img alt="" bind:this={image} src="/">
        </div>
        <div class="small-6 columns">
            {#each $cats$ as cat}
                <button class:active={catId == cat.id} on:click|preventDefault={() =>catId = cat.id}>{cat.name[lang]}</button>
            {/each}
            <hr>
            <textarea placeholder="{__('Σχόλια')}..." class="block" name="text[{id}]"></textarea>
            <hr>
            <button on:click|preventDefault={deleteMe}>{__('Διαγραφή')}</button>
        </div>
    </div>
{:else}
    {#if id < 1}
        <div class="row">
            <div class="small-12 columns text-center">
                <img src="/img/choose_button.png" on:click={ enable}/>
            </div>
        </div>
    {:else}
        <div class="link text-center" on:click|preventDefault={ enable}>{__('Ανέβασε και άλλη')}</div>
    {/if}

{/if}


<style>
    button {
        display: block;
        width: 100%;
        margin: 10px 0;
    }

    button.active {
        background-color: #8ac13e;
    }

    textarea {
        min-height: 200px;
    }
</style>


