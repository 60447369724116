<script>
    import {__, API, cats$, data$, DMYHI, getLang, showMessage, user$} from "../js/lib.js";
    import CatChooser from "./CatChooser.svelte";
    import CommentsPopup from "./CommentsPopup.svelte";
    import {createEventDispatcher, tick} from "svelte/internal";
    import lazyImage from "../js/lazyImage.js";

    const isAdmin = $user$.is_admin;

    export let fromTool = '';
    export let photo;


    const dispatch = createEventDispatcher();

    let showCatChooser = false;
    let showCommentForm = false;
    let showDoForm = false;
    let showDontForm = false;
    let showEditDescription = false;

    const lang = getLang();
    
    const username = $data$.users.find(u => u.id == photo.user_id).fullname;
    let category = $cats$.find(c => c.id == photo.cat_id).name[lang];
    const date = DMYHI(photo.created_at);
    
    
    
    const changeCategory = async ({detail: cat_id}) => {
        if (cat_id === null) {
            showCatChooser = false;
            return;
        }
        const res = await API.post('changeCategory', {
            id: photo.id,
            cat_id
        });

        if (res.data == true) {
            showCatChooser = false;
            category = $cats$.find(c => c.id == cat_id).name[lang];
            photo.cat_id = cat_id
        } else {
            alert('Κάποιο λάθος συνέβη!');
        }
    };

    const saveComment = async ({detail: comments}) => {
        dd(comments[lang]);
        if (comments[lang].trim().length < 2) {
            alert('Θα πρέπει να δώσετε ένα σχόλιο');
            return;
        }
        const res = await API.post('addComment', {
            id: photo.id,
            comments: comments[lang]
        });

        if (res.data == false) {
            alert('Κάποιο λάθος συνέβη!');
        } else {
            showCommentForm = false;
            $data$.talkedPhotos++;
            fixPhotoPlace();

            showMessage('Η αποθήκευση έγινε');
            await tick();
            dispatch('remove', photo);
        }
    };

    const saveDo = async ({detail: comments}) => {
        const res = await API.post('addDo', {
            id: photo.id,
            comments: comments[lang]
        });

        if (res.data == true) {
            showDoForm = false;

            showMessage('Η φωτό μπήκε στα DOs');
            fixPhotoPlace();
            await tick();
            dispatch('remove', photo);
        } else {
            alert('Κάποιο λάθος συνέβη!');
        }
    };

    const saveDont = async ({detail: comments}) => {
        const res = await API.post('addDont', {
            id: photo.id,
            comments: comments[lang]
        });

        if (res.data == true) {
            showDontForm = false;
            showMessage('Η φωτό μπήκε στα DONTs');
            fixPhotoPlace();
            await tick();
            dispatch('remove', photo);
        } else {
            alert('Κάποιο λάθος συνέβη!');
        }
    };

    const deletePhoto = async () => {
        if (!confirm('Εισαστε σίγουροι για τη διαγραφή;\nΔεν υπάρχει undo.')) {
            return;
        }

        const res = await API.post('deletePhoto', {
            id: photo.id,
        });

        if (res.data == true) {
            showMessage('Η διαγραφή έγινε');
            fixPhotoPlace();
            await tick();
            dispatch('remove', photo);

        } else {
            alert('Κάποιο λάθος συνέβη!');
        }
    };

    const saveDescription = async ({detail: comments}) => {
        const res = await API.post('editPhotoDescription', {
            id: photo.id,
            comments: comments[lang]
        });

        if (res.data == true) {
            photo.comments = comments;
            showEditDescription = false;
            showMessage('Η επεξεργασία έγινε');
        } else {
            alert('Κάποιο λάθος συνέβη!');
        }
    };

    const fixPhotoPlace = where => {
        if (fromTool == 'new') {
            $data$.newPhotos = $data$.newPhotos - 1;
        }
    };

    const displayLightbox = () => {
        dispatch('lightbox', photo);
    };


</script>


<div class="item">
    <div class="new-photo hoverable">
        <div class="my-id">{photo.id}</div>
        <div class="row"  class:only-admin={!isAdmin}>
            <div class="column small-12 ribbon">
                <div style="padding-bottom: 5px;" class="small-info">{__('Από')} <b>{username}</b> {date}</div>
                <span class:link={isAdmin} class="tag small block" on:click={() => showCatChooser = true}>
                    {category}
                    {#if isAdmin}
                    <img class="pull-right" src="/img/edit.png" alt="edit" style="margin:2px 0 0 10px; " width="14" height="14">
                    {/if}
                </span>
            </div>

            <div class="column small-12">
                <img use:lazyImage data-src="/{photo.filename}" alt="" on:click={displayLightbox}>
            </div>

            <div class="column small-12 buttons-place">
                {#if fromTool != 'dos'}
                    <button class="dos" on:click={() => showDoForm = true}>
                        {__('Στα DOs')}
                    </button>
                {/if}

                {#if fromTool != 'donts'}
                    <button class="donts" on:click={() => showDontForm = true}>
                        {__('Στα DONTs')}
                    </button>
                {/if}

                <button class="notes" on:click={() => showCommentForm = true}>
                    {__('Παρατηρήσεις')}
                </button>

                <button class="delete" on:click={deletePhoto}>{__('Διαγραφή')}</button>
            </div>

            <div class="column small-12 ribbon no-gap">
                {#if isAdmin}
                    <img class="pull-right link" src="/img/edit.png" alt="edit"
                         on:click={() => showEditDescription = true}>
                {/if}
                {#if photo.comments[lang]?.length > 1}
                    {photo.comments[lang]}
                {:else}
                    ({__('χωρίς περιγραφή')})
                {/if}
            </div>
        </div>
    </div>
</div>

{#if isAdmin}
    {#if showCatChooser}
        <CatChooser on:select={changeCategory} selected={photo.cat_id}/>
    {/if}

    {#if showCommentForm}
        <CommentsPopup on:save={saveComment} on:close={() => showCommentForm = false}>
            {__('Παρακαλώ δώστε τα σχόλιά σας για αυτή τη φωτογραφία')}.
        </CommentsPopup>
    {/if}

    {#if showEditDescription}
        <CommentsPopup on:save={saveDescription} comments={photo.comments} on:close={() => showEditDescription = false}>
            {__('Δώστε περιγραφή για την φωτογραφία')}
        </CommentsPopup>
    {/if}

    {#if showDoForm}
        <CommentsPopup title="Προσθήκη στα DOs" on:save={saveDo} on:close={() => showDoForm = false}>
            <span class="tag do-colors">{__('Θα προσθέσετε αυτή τη φωτογραφία στα')} DOs</span><br>
            {__('Παρακαλώ δώστε τα σχόλιά σας για αυτή τη φωτογραφία')} ({__('προαιρετικό')}).
        </CommentsPopup>
    {/if}

    {#if showDontForm}
        <CommentsPopup title="Προσθήκη στα DONTs" on:save={saveDont} on:close={() => showDontForm = false}>
            <span class="tag dont-colors">{__('Θα προσθέσετε αυτή τη φωτογραφία στα')} DONTs</span><br>
            {__('Παρακαλώ δώστε τα σχόλιά σας για αυτή τη φωτογραφία')} ({__('προαιρετικό')}).
        </CommentsPopup>
    {/if}
{/if}


<style>
    .my-id {
        position: absolute;
        right: 0;
        top:0;
        font-size: 12px;
        background: #000;
        color: coral;
        z-index: 1;
        padding: 1px 10px;
    }
    .new-photo {
        background: #fff;
        position: relative;
        padding: 10px 10px 60px 10px;
        margin-bottom: 15px;
        border: 1px solid #aaa;
        box-shadow: 3px 3px 8px 0 #0006;
        overflow: hidden;
        height: 100%;
    }

    .new-photo img {
        object-fit: contain;

    }

    .new-photo button {
        display: block;
        width: 90%;
        float: right;
        margin-top: 10px;
        margin-left: 10px;
    }

    .only-admin .buttons-place {
        display: none;
    }

    .no-gap {
        transition: .3s;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -50px;
        border-left: none;
        border-right: none;
        border-bottom: none;
        max-height:60px;
        overflow: hidden;
    }
    .no-gap:hover {
        max-height: 400px;
    }

    .new-photo > .row {
        position: relative;
        height: 100%;
        padding-bottom: 140px;
    }
    .new-photo > .row.only-admin {
        padding-bottom: 0;
    }

    .buttons-place {
        border-top: 1px solid #000;
        background-color: #f3fff3;
        position: absolute;
        bottom: -16px;
        padding: 0 0 20px 0;
    }
    
    .hoverable .buttons-place {
        max-height:0;
        transition:.3s;
    }
    .hoverable:hover .buttons-place {
        max-height:200px;
    }
</style>