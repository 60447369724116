<script>
    import {API, cats$, data$, getCookieValue, setToken, user$} from "./js/lib.js";
    import LoginBox from "./components/LoginBox.svelte";
    import Route from "./lib/Router/Router.svelte";
    import Header from "./components/Header.svelte";
    import TabHeader from "./components/TabHeader.svelte";
    import ImageUpload from "./components/ImageUpload.svelte";
    import DosDontsList from "./components/DosDontsList.svelte";
    import NewPhotos from "./components/NewPhotos.svelte";
    import Messanger from "./components/Messanger.svelte";
    import TalkedPhotos from "./components/TalkedPhotos.svelte";
    import BackRibbon from "./components/BackRibbon.svelte";
    import Translations from "./components/Translations.svelte";

    let isLoggedIn = false;
    let msg = '';
    let loading = true;
    

    const checkLogin = async () => {
        
        const cookie = getCookieValue('dd_eshop');
        

        if (!cookie) {
            loading = false;
            isLoggedIn = false;
            return;
        } else {
            setToken(cookie);
        }
        loading = true;
        const res = await API.get('userAuth', {}, true);
        loading = false;
        user$.set(res.data.user);
        cats$.set(res.data.cats);
        data$.set(res.data.data);
        isLoggedIn = true;
    };

    const calcMessage = url => {
        const params = new URLSearchParams(window.location.search);
        const key = params.get('msg');
        msg = {
            uploaded: 'Η αποθήκευση έγινε'
        }[key] || '';

        if (msg) {
            setTimeout(() => {
                msg = '';
            }, 3000);
        }
    };

    checkLogin();
    calcMessage();
</script>

<main>
    {#if !loading}
    {#if isLoggedIn}
        <Route match="/">
            <Route exact redir="/dos"></Route>
            <Header/>

            {#if msg}
                <div class="row info">{msg}</div>
            {/if}


            <Route match="/new" guard={$user$.is_admin}>
                <BackRibbon />
                <NewPhotos/>
            </Route>
            
            <Route match="/translations" guard={$user$.is_admin}>
                <BackRibbon />
                <Translations/>
            </Route>

            <Route match="/talk">
                <BackRibbon />
                <TalkedPhotos/>
            </Route>

            <Route match="/dos">
                <TabHeader selected="dos"/>
                <DosDontsList fromTool="dos"/>
            </Route>


            <Route match="/donts">
                <TabHeader selected="donts"/>
                <DosDontsList fromTool="donts"/>
            </Route>

            <Route match="/upload">
                <TabHeader selected="upload"/>
                <ImageUpload/>
            </Route>

        </Route>


    {:else}
        <LoginBox/>
    {/if}
        {/if}
</main>

<Messanger />

<style>
    .info {
        margin-bottom: 30px;
        padding: 10px;
        border: 1px solid #383;
        font-size: 20px;
        background-color: #dfd;
        border-radius: 20px;
        text-align: center;
        color: darkcyan;
    }
</style>