<script>
    import {createEventDispatcher} from "svelte/internal";
    import {getLang} from "../js/lib.js";

    export let photo;
    export let photos;

    const dispatch = createEventDispatcher();

    const lang = getLang();
    let idx = photos.indexOf(photo);
    const maxIndex = photos.length - 1;

    const left = () => {
        idx--;
        photo = photos[idx];
    };
    const right = () => {
        idx++;
        photo = photos[idx];
    };


    const close = () => dispatch('close')

</script>

<div class="popup">
    <span class="close" on:click={close}>&times;</span>

        {#if idx > 0}
            <span class="arrow left" on:click={left}></span>
        {/if}

        {#if idx < maxIndex}
            <span class="arrow right" on:click={right}></span>
        {/if}
    <div class="window">
        <h2>Photo Details</h2>

        <div class="img-place">
            <img src="/{photo.filename}">
        </div>
        <div>{photo.comments[lang]}</div>
    </div>
</div>

<style>
    .img-place {
        text-align: center;
    }
    .popup {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        background-color: #111d;
    }

    .window {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: #fff;
        border: 1px solid #000;
        transform: translate(-50%, -50%);
    }

    .window h2 {
        padding: 10px;
        background-color: #000;
        color: #fff;
    }

    .window > div {
        padding: 10px;
    }

    .window button[disabled] {
        background-color: #ddd;
    }

    .window button[disabled]:hover {
        background-color: #ddd;
    }

    .close, .arrow {
        position: absolute;
        width: 60px;
        height: 60px;
        cursor: pointer;
    }

    .close {
        top: 0;
        right: 0;
        font-size: 50px;
        color: red;
        line-height: 40px;
        text-align: center;
        background-color: #fff;
    }

    .arrow {
        top: 50%;
        border-left: 8px solid #fff;
        border-bottom: 8px solid #fff;

    }

    .arrow.left {
        transform: translateY(-50%) rotate(45deg);
        left: 40px;
    }

    .arrow.right {
        transform: translateY(-50%) rotate(-135deg);
        right: 40px;
    }
</style>