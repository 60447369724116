<script>
    import {msg$} from "../js/lib.js";

    let visible = false;
    let msg = null;
    let tm = null;

    const clear = () => {
        clearTimeout(tm);
        $msg$ = null;
    };

    $: visible = $msg$ != null;
    $: if ($msg$ !== null) {
        msg = $msg$;
        tm = setTimeout(() => {
            $msg$ = null;
        }, 5000);
    }
</script>

<div class="msg text-right link" class:visible on:click={clear}>
    <span class="pull-right">&nbsp; &nbsp; &nbsp;&times;</span>
    {msg}
</div>

<style>
    .msg {
        position: fixed;
        bottom: -46px;
        left: 0;
        right: 0;
        padding: 8px 16px;
        background-color: gold;
        font-size: 18px;
        border-radius: 4px;
        transition: .4s;
    }

    .msg.visible {
        bottom: 0px;
    }
</style>
