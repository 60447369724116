<script>
    import ChatItem from "./ChatItem.svelte";

    export let chats;
</script>

{#if chats}
    <div class="all-chats">
        {#each chats as chat}
            <ChatItem {chat}/>
        {/each}
    </div>
{/if}


<style>
    .all-chats {
        border:1px solid #ddd;
    }
</style>