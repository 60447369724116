<script>
    import {__, API, cats$, getLang} from "../js/lib.js";
    import SinglePhoto from "./SinglePhoto.svelte";

    let loaded = false;
    let photos = [];
    let page = 1;
    let totalPages = 1;
    let catId = 0;

    let filtered = [];
    
    const lang = getLang();

    const init = async () => {
        await loadPage(page, catId);
        loaded = true;
    };

    const loadPage = async (page, catId = 0) => {
        const res = await API.get('newPhotos', {
            page,
            catId
        });
        ({photos, page, totalPages} = res.data);
        loaded = true;
    };

    const setCat = id => {
        page = 1;
        totalPages = 1;
        catId = id;
        loadPage(page, catId)
    };

    const removeMe = photo => () => {
        photos = photos.filter(p => p != photo);
    };

    const filterPhotos = (p, c) => {
        if (c == 0) {
            return p;
        }

        return p.filter(photo => photo.cat_id == c);
    };

    init();

    $: filtered = filterPhotos(photos, catId);

</script>

{#if loaded}
    <div class="row">
        <div class="column small-12 medium-3 side-menu">
            <h3>{__('Κατηγορίες')}</h3>
            {#each $cats$ as cat}
                <div class="text-link a-cat" class:selected={catId == cat.id} on:click={setCat(cat.id)}>{cat.name[lang]}</div>
            {/each}
        </div>

        <div class="column small-12 medium-9">
            <div class="flex2">
                {#if (filtered.length)}
                    {#each filtered as photo}
                        <SinglePhoto
                                fromTool="new"
                                {photo}
                                on:remove={removeMe(photo)}
                        />
                    {/each}
                {:else}
                    <div style="background-color: #fff; padding:20px; text-align: center">
                        {__('Δεν υπάρχει καμία φωτογραφία')}
                    </div>
                {/if}
        </div>
        </div>
    </div>
{:else}
    please wait...
{/if}


<style>
    .side-menu {
        background: #fff;
        position: relative;
        padding: 10px;
        border: 1px solid #aaa;
        box-shadow: 3px 3px 8px 0 #0006;
    }

    .side-menu h3 {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #aaa;
    }

    .side-menu .a-cat {
        padding: 5px 10px;
    }

    .side-menu .a-cat:hover {
        background-color: cornsilk;
    }

    .side-menu .a-cat.selected {
        background-color: chocolate;
        color: #fff;
    }
</style>