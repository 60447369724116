<script>

    import {createEventDispatcher} from "svelte/internal";
    import {__, cats$, data$, DMYHI, getLang} from "../js/lib.js";

    export let photo;
    const dispatch = createEventDispatcher();

    const lang = getLang();
    const username = $data$.users.find(u => u.id == photo.user_id).fullname;
    let category = $cats$.find(c => c.id == photo.cat_id).name;
    const date = DMYHI(photo.created_at);

    let translation = photo.comments;

    const save = () => {
        dispatch('save', translation);
    }


</script>


<div class="column small-12">
    <div class="talk-photo">
        <div class="row">
            <div class="column small-3">
                <img src="/{photo.filename}" alt=""><br>
                <div class="pad-v">
                <span class="tag block text-center">
                    {category[lang]}
                </span>
                </div>
                <div class="text-small">
                    {date}<br>
                    {__('Από')} {username}
                </div>
            </div>

            <div class="column small-9">
                {#each Object.keys(photo.comments) as key}
                    <div class="bold" style="margin-bottom:0;">{key}</div>
                    {#if lang == key}
                        <textarea bind:value={translation[lang]}></textarea>
                    {:else}
                        <div>{photo.comments[key] || '-'}</div>
                    {/if}
                {/each}
                <div class="pull-right pad-v">
                    <a href="#" class="tag new" on:click|preventDefault={save}>{__('Αποθήκευση')}</a>
                </div>
            </div>
        </div>

    </div>

</div>

<style>
    .talk-photo {
        background: #fff;
        position: relative;
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #aaa;
        box-shadow: 3px 3px 8px 0 #0006;
    }

    .talk-photo img {
        object-fit: contain;

    }

    .talk-photo button {
        display: block;
        width: 100%;
        float: right;
        margin-top: 5px;
        margin-left: 15px;
    }
</style>