<script>
    import {__, API, showMessage} from "../js/lib.js";
    import {createEventDispatcher} from "svelte/internal";

    const dispatch = createEventDispatcher();
    export let photo;

    let comments = '';

    const sendComment = async () => {
        if (comments.length < 1) {
            return;
        }

        const res = await API.post('addComment', {
            id: photo.id,
            comments: comments.trim()
        });

        showMessage('Το σχόλιο προστέθηκε');
        dispatch('save', res.data);
    }
</script>

<div>
    <br>
    <textarea placeholder="{__('Παρακαλώ δώστε τα σχόλιά σας για αυτή τη φωτογραφία')}" bind:value={ comments }></textarea>
    <div class="text-right pad-v">
        <button class="do-colors" on:click={sendComment} disabled={comments.trim().length < 2}>{__('Αποθήκευση')}</button>
    </div>
</div>


<style>
    button[disabled] {
        background-color:#aaa;
    }
</style>