<script>
    import {__, API, data$, showMessage} from "../js/lib.js";
    import TranslationPhoto from "./TranslationPhoto.svelte";

    let photos = [];
    let loaded = false;

    const init = async () => {
        loaded = false;
        const {err, data} = await API.get('translations');
        photos = data;
        loaded = true;
    };

    const save = photo => async ({detail}) => {
        const {err, data} = await API.post(`translationSave`, {
            comments: detail,
            id: photo.id
        });

        await init();
        showMessage('Η αποθήκευση έγινε');
        $data$.translations = $data$.translations.filter(t => t != photo.id);
    };


    init();
</script>


{#if loaded}
    {#if photos.length}
        <div class="row">
            {#each photos as photo}
                <div class="column small-12">
                    <TranslationPhoto
                            {photo}
                            on:save={save(photo)}
                    />
                </div>
            {/each}
        </div>
    {:else}
        <div class="row">
            {__('Δεν υπάρχουν μεταφράσεις')}
        </div>
    {/if}
{:else}
    <div class="row">
        loading...
    </div>
{/if}