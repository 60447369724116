import {writable} from "svelte/store";

let authToken = '';

const API_BASE = '/api/v1/';

const RE_CONTENT_TYPE_JSON = new RegExp("^application/(x-)?json", "i");
const RE_CONTENT_TYPE_TEXT = new RegExp("^text/", "i");
const UNEXPECTED_ERROR_MESSAGE = "An unexpected error occurred while processing your request.";

export const user$ = writable({});
export const cats$ = writable([]);
export const data$ = writable({});
export const msg$ = writable(null);

export const showMessage = msg => {
    msg$.set(msg);
};

export const getCookieValue = name => {
    return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
};

export const getLangCode = id => {
    return ['', 'el', 'ro'][id];
};

export const getLang = () => getLangCode(getCookieValue('dd_eshop_lang'));

export const setLanguage = lang => {
    updateCookie('dd_eshop_lang', lang);
    document.location.reload();
};

function updateCookie(name, newValue, days) {
    if (!days) {
        days = 1440;
    }
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "; expires=" + date.toGMTString();
    document.cookie = name + "=" + newValue + expires + "; path=/";
}

class ApiClient {
    constructor() {
    }

    get(url, params, nc = false) {
        return this.makeRequest({url, params, method: 'get'}, nc = false);
    }

    post(url, json, nc = false) {
        return this.makeRequest({url, json, method: 'post'}, nc = false);
    }

    delete(url, json, nc = false) {
        return this.makeRequest({url, json, method: 'delete'}, nc = false);
    }

    patch(url, json, nc = false) {
        return this.makeRequest({url, json, method: 'patch'}, nc = false);
    }

    put(url, json, nc = false) {
        return this.makeRequest({url, json, method: 'put'}, nc = false);
    }

    async makeRequest(config, nc = false) {
        try {
            const contentType = (config.contentType || 'application/x-json');
            const headers = (config.headers || Object.create(null));
            const method = (config.method || null);
            const url = (config.url || "");
            const params = (config.params || Object.create(null));
            const form = (config.form || null);
            const body = (config.body || null);

            const fetchUrl = API_BASE + this.mergeParamsIntoUrl(url, params);
            let fetchMethod = method || "get";


            const fetchHeaders = this.buildHeaders(headers);
            fetchHeaders['authorization'] = 'Bearer ' + authToken;

            const json = (config.json || null);
            let fetchBody;
            if (json) {
                fetchBody = JSON.stringify(json);
            }

            if (form) {
                delete (fetchHeaders["content-type"]);
                fetchMethod = "post";
                fetchBody = this.buildFormData(form);
            }

            if (body) {
                fetchHeaders["content-type"] = (contentType || "application/octet-stream");
                fetchMethod = (method || "post");
                fetchBody = body;
            }

            if (nc) {
                delete (fetchHeaders["authorization"]);
            }


            const fetchRequest = new window.Request(
                fetchUrl,
                {
                    headers: fetchHeaders,
                    method: fetchMethod,
                    body: fetchBody
                }
            );

            const fetchResponse = await window.fetch(fetchRequest);
            const data = await this.unwrapResponseData(fetchResponse);

            if (fetchResponse.ok) {
                return data;
            }

            return Promise.reject(this.normalizeError(data));
        } catch (error) {
            return Promise.reject(this.normalizeTransportError(error));
        }
    }

    buildFormData(formFields) {
        const formData = new FormData();
        Object.entries(formFields).forEach(([key, value]) => {
            formData.append(key, value);
        });

        return formData;
    }

    buildHeaders(headers) {
        const lowercaseHeaders = Object.create(null);
        Object.entries(headers).forEach(([key, value]) => {
            lowercaseHeaders[key.toLowerCase()] = value;
        });
        // if (this.bearer) {
        //     lowercaseHeaders['authorization'] = 'Bearer ' + this.bearer
        // }

        return lowercaseHeaders;
    }

    buildQueryString(params) {
        return Object.entries(params).map(([key, value]) => {
            if (value === true) {
                return encodeURIComponent(key);
            }
            return encodeURIComponent(key) + "=" + encodeURIComponent(value);
        }).join("&");

    }

    mergeParamsIntoUrl(url, params) {
        const hashParts = url.split("#", 2);
        const preHash = hashParts[0];
        const fragment = (hashParts[1] || "");

        const urlParts = preHash.split("?", 2);
        const scriptName = urlParts[0];

        const urlParams = this.parseQueryString(urlParts[1] || "");
        const mergedParams = Object.assign(urlParams, params);
        const queryString = this.buildQueryString(mergedParams);

        const results = [scriptName];

        if (queryString) {
            results.push("?", queryString);
        }

        if (fragment) {
            results.push("#", fragment);
        }

        return results.join("");
    }

    normalizeError(data) {
        const error = {
            type: "ServerError",
            message: UNEXPECTED_ERROR_MESSAGE
        };

        if (
            (typeof (data?.type) === "string") &&
            (typeof (data?.message) === "string")
        ) {
            return Object.assign(error, data);
        } else {
            error.rootCause = data;
            return error;
        }
    }

    normalizeTransportError(transportError) {
        return {
            type: "TransportError",
            message: UNEXPECTED_ERROR_MESSAGE,
            rootCause: transportError
        };
    }

    parseQueryString(queryString) {
        const params = Object.create(null);

        for (const pair of queryString.split("&")) {
            const parts = pair.split("=", 2);
            const key = decodeURIComponent(parts[0]);
            params[key] = parts[1] ? decodeURIComponent(parts[1]) : true;
        }
        return params;
    }

    async unwrapResponseData(response) {
        const contentType = response.headers.has("content-type")
            ? response.headers.get("content-type")
            : ""
        ;

        if (RE_CONTENT_TYPE_JSON.test(contentType)) {
            return response.json();
        } else if (RE_CONTENT_TYPE_TEXT.test(contentType)) {
            return response.text();
        } else {
            return response.blob();
        }
    }
}

export const API = new ApiClient();
export const setToken = t => authToken = t;
export const DMYHI = d => {
    const [dateP, hourP] = d.split('T');
    const hm = hourP.split(':');
    return `${dateP.split('-').reverse().join('-')} ${hm[0]}:${hm[1]}`;
};


const trans = {
    'Χριστούγεννα': 'Craciun',
    'Πάσχα': 'Paste',
    'Καλοκαίρι': 'Vara',
    'Εύθραυστα': 'Fragil',
    'Ογκώδη': 'Colete mari',
    'Κήπος': 'Gradina',
    'Κατηγορίες': 'Categorii',
    'Από': 'De la',
    'χωρίς περιγραφή': 'Fara descriere',
    'Παρατηρήσεις': 'Observatii',
    'Διαγραφή': 'Sterge',
    'Στα DOs': 'La Dos',
    'Στα DONTs': 'La DONTs',
    'Προσθήκη στα DOs': 'Adauga la DOs',
    'Προσθήκη στα DONTs': 'Adauga la D0NTs',
    'Θα προσθέσετε αυτή τη φωτογραφία στα': 'Adauga aceasta poza la',
    'Παρακαλώ δώστε τα σχόλιά σας για αυτή τη φωτογραφία': 'Comenteaza la aceastapoza ',
    'προαιρετικό': 'Optional',
    'Ακυρο': 'Anuleaza',
    'Αποθήκευση': 'Salveaza',
    'Σχόλια': 'Comentarii',
    'Δώστε περιγραφή για την φωτογραφία': 'Dati o descriere pentru fotografie',
    'Δεν υπάρχει καμία φωτογραφία': 'Nu exista fotografie disponibila',
    'Δεν υπάρχουν μεταφράσεις': 'Nu exista traduceri disponibie',
    'Η αποθήκευση έγινε': 'Salvati',
    'Η διαγραφη έγινε': 'Sterge',
    'Η επεξεργασια έγινε': 'Procesat',
    'Επιστροφή στα': 'Intoarcere la',
    'Νέες': 'Noi',
    'Συζήτηση': 'Chat',
    'Ανέβασε και άλλη': 'Adauga inca una',
    'Κάντε click στην κατηγορία που θέλετε να μεταφέρετε την φωτογραφία': 'Da clic pe categoria în care doresti sa transferi fotografia',
    'Μεταφράσεις': 'Traducere',
    'Είσοδος': 'Intrare',
    'Η κατηγορία άλλαξε': 'Categoria schimbata',
    'Κάποιο λάθος συνέβη': 'Ceva nu a mers bine',
};

const current_lang = getLang();
export const __ = str => {
    if (current_lang == 'el') {
        return str;
    }

    const translation = trans[str];

    return translation?.length > 0 ? translation : `!! ${str} !!`;
};