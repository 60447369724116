import inViewport from './inViewport';

export default function lazyImage(element) {
	const viewportActionMethods = inViewport(element);
	element.addEventListener('enterViewport', enterViewport);

	function enterViewport() {
		element.src = element.dataset.src;
		element.removeEventListener('enterViewport', enterViewport);
	}

	return {
		destroy() {
			viewportActionMethods.destroy();
			element.removeEventListener('enterViewport', enterViewport);
		}
	}
}