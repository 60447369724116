<script>
    import A from "../lib/Router/A.svelte";
    import {__} from "../js/lib.js";
</script>

<div class="row">
    <div class="small-12 columns text-right" style="padding:5px;">
        {__('Επιστροφή στα')}
        <A href="/dos" class="tag do-colors">DOs</A>
        <A href="/donts" class="tag dont-colors">DONTs</A>
    </div>
</div>
<div>&nbsp;</div>