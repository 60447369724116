<script>
    import {data$, DMYHI, user$} from "../js/lib.js";

    export let chat;
    const user = $data$.users.find(u => u.id == chat.user_id);

    const date = DMYHI(chat.created_at);
</script>


<div class="a-chat" class:not-mine={user.id != $user$.id}>
    <div class="det">
        <div>{user.fullname}</div>
        <div class="info">
            {date}
        </div>
    </div>
    <div class="chat">
        {chat.comment}
    </div>
</div>


<style>
    .a-chat {
        border-bottom: 1px solid #ddd;
        box-sizing: border-box;
        padding: 15px 10px;
    }
    .a-chat.not-mine {
        background-color: #fffffa;
    }
    .a-chat .det {
        vertical-align: top;
        font-size:12px;
        width: 20%;
        display: inline-block;
        box-sizing: border-box;
    }

    .a-chat .chat {
        width: 75%;
        vertical-align: top;
        display: inline-block;
        box-sizing: border-box;
    }
</style>